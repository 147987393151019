<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
import { useCameraRollsStore } from '~~/store/camera-rolls';
import { useUserStore } from '~~/store/user';
import { HydratedCameraRoll } from '~~/types/types';
import { useRouter } from 'vue-router';
import { leaveCameraRoll } from "~/composables/useCameraRoll";

const rolls = useCameraRollsStore()
const userStore = useUserStore()
const i18n = useI18n()
const toast = useToast()
const { user, userState } = storeToRefs(userStore)

const props = defineProps({
    cameraRoll: {
        type: Object as PropType<HydratedCameraRoll>,
        required: false
    },
})

const showDeleteDialog = ref(false)
const showLeaveDialog = ref(false)
const router = useRouter()


function toggleDialog(isOwner: boolean) {
  isOwner ? showDeleteDialog.value = true : showLeaveDialog.value = true;
}


async function deleteRoll() {
  if (!props.cameraRoll) return;

  try {
    await rolls.deleteCameraRoll(props.cameraRoll.id)
    router.push('/camera-rolls')
  } catch (e) {
    toast.error(i18n.t('common.error'))
  }
}

async function leaveRoll() {
  if (!props.cameraRoll) return;

  try {
    await leaveCameraRoll(props.cameraRoll.id as string)
    router.push('/camera-rolls')
  } catch (e) {
    toast.error(i18n.t('common.error'))
  }
}
</script>

<template>
  <div v-if="cameraRoll"
       class="mt-6 w-full container rounded-2xl bg-stone-100 p-3 py-1 text-left text-red-600 cursor-pointer"
       @click="toggleDialog(cameraRoll.userIsOwner)" data-qa="button-delete-or-leave">
    <div class="flex justify-center border-t-stone-100 border-t pt-3 pb-3 text-sm font-medium">
      <div class="flex items-center">{{ cameraRoll?.userIsOwner ? $t('camera-rolls.actions.delete-roll') : $t('camera-rolls.actions.leave-roll') }}</div>
    </div>
  </div>
  <teleport to="body">
    <CommonDialog :show="showDeleteDialog" :positive-label="$t('slideshow.delete.positiveLabel')"
                  :negative-label="$t('slideshow.delete.negativeLabel')" :body="$t('slideshow.delete.body')"
                  @click-negative="showDeleteDialog = false" @click-positive="deleteRoll" />
    <CommonDialog :show="showLeaveDialog" :positive-label="$t('camera-rolls.actions.leave.positiveLabel')"
                  :negative-label="$t('camera-rolls.actions.leave.negativeLabel')" :body="$t('camera-rolls.actions.leave.body')"
                  @click-negative="showLeaveDialog = false" @click-positive="leaveRoll" />
  </teleport>
</template>
