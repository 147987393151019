<script setup lang="ts">
import {Capacitor} from '@capacitor/core'
import {ref} from 'vue';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera'
import {decode} from 'base64-arraybuffer'
import {useCameraRollsStore} from '~~/store/camera-rolls'
import {useUserStore} from '~~/store/user'
import {UserStates} from '~~/types/enums'
import {storeToRefs} from 'pinia'
import {useToast} from "vue-toastification";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from 'vue-router';

const emits = defineEmits(['toggle-loading'])

const route = useRoute()
const userStore = useUserStore()

const cameraRollId = ref<string>('')
const showGuard = ref(false)
const isUploadFromLibrary = ref(false)
const toast = useToast()
const i18n = useI18n()
const router = useRouter()


cameraRollId.value = route.params.id as string

const {userState} = storeToRefs(userStore)

const isImageMimeType = (mimeType: string)  => {
  // Define a list of allowed image MIME types
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/svg+xml'];

  return allowedImageTypes.includes(mimeType);
}

const takePicture = async (fromLibrary = false) => {
  // todo: check if showGuard is necessary
  isUploadFromLibrary.value = fromLibrary

  if (userState.value === UserStates.AnonymousGuest || !userState.value) {
    showGuard.value = true
    return
  } else {
    showGuard.value = false
  }

  if (fromLibrary) {
    const galleryPhotos = await Camera.pickImages({
      quality: 90,
      // @ts-ignore
      webUseInput: window.Cypress ? false : true,
      saveToGallery: true,
      resultType: Capacitor.isNativePlatform() ? CameraResultType.Base64 : CameraResultType.Uri,
    })
    emits('toggle-loading', true)
    for (const photo of galleryPhotos.photos) {
      try {
        // Turn the picture into a blob
        let blob: Blob = await photoToBlob(photo)

        if (isImageMimeType(blob.type)) {
          await uploadBlob(blob, photo)
        } else {
          toast.error(i18n.t('upload.image-type-error'))
        }
      } catch (e) {
        toast.error(i18n.t('common.error'))
      }
    }
    emits('toggle-loading', false)

    router.push(`/camera-roll/${cameraRollId.value}`)
  } else {
    const photo = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      source: CameraSource.Camera,
      // @ts-ignore
      webUseInput: window.Cypress ? false : true,
      saveToGallery: true,
      resultType: Capacitor.isNativePlatform() ? CameraResultType.Base64 : CameraResultType.Uri,
    })
    emits('toggle-loading', true)

    // Turn the picture into a blob
    let blob: Blob = await photoToBlob(photo)
    if (isImageMimeType(blob.type)) {
      await uploadBlob(blob, photo)
    } else {
      toast.error(i18n.t('upload.image-type-error'))
    }
    emits('toggle-loading', false)
    router.push(`/camera-roll/${cameraRollId.value}`)
  }

}

const photoToBlob = async (photo) => {
  if (Capacitor.isNativePlatform() && photo.base64String) {
    return new Blob([new Uint8Array(decode(photo.base64String!))], {
      type: `image/${photo.format}`,
    })
  } else {
    const response = await fetch(photo.webPath!)
    return await response.blob()
  }
}

const uploadBlob = (blob, photo) => {
  return new Promise<void>((resolve, reject) => {
    // determine dimensions of image
    // source: https://stackoverflow.com/questions/623172/how-to-get-the-image-size-height-width-using-javascript
    let image = new Image();
    image.onload = async function () {
      try {
        const store = useCameraRollsStore()
        await store.storePhoto(cameraRollId.value, photo, blob, image.width, image.height)
        resolve()
      } catch (e) {
        reject(e)
      }
    };
    image.onerror = function (e) {
      reject(e)
    }

    let urlCreator = window.URL || window.webkitURL;
    let imageUrl = urlCreator.createObjectURL(blob);
    image.src = imageUrl;
  })
}

function submitUploadGuard() {
  window.setTimeout(() => {
    takePicture(isUploadFromLibrary.value)
  }, 500)
}
</script>

<template>
  <div>
    <CameraRollUploadGuard
        :show="showGuard"
        :targetpath="route.path"
        @submit="submitUploadGuard()"
        @close="showGuard = false"
    />
    <div class="container fixed flex flex-col max-w-4xl justify-between bottom-2 pointer-events-none items-center px-4">
      <div
          class="text-3xl bg-stone-900 rounded-full flex justify-center items-center text-white mb-3 h-16 w-16 drop-shadow-[0_0_10px_rgba(255,255,255,1)] transition-all duration-500 active:bg-stone-500 pointer-events-auto"
          @click="takePicture(false)"
      >
        <Icon icon="mdi:camera" data-qa="fab-take-picture"/>
      </div>
      <div
          class="text-3xl bg-stone-900 rounded-full flex justify-center items-center text-white mb-5 h-10 w-10 drop-shadow-[0_0_10px_rgba(255,255,255,1)] transition-all duration-500 active:bg-stone-500 pointer-events-auto"
          @click="takePicture(true)"
      >
        <Icon icon="mdi:image" data-qa="fab-take-picture-from-librarys" height="20"/>
      </div>
    </div>
  </div>
</template>
