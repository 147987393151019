<script setup lang="ts">
import { useRuntimeConfig } from '#app'
import { Carousel, Slide } from 'vue3-carousel'
import { useCameraRollsStore } from '~~/store/camera-rolls'
import 'vue3-carousel/dist/carousel.css'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { HydratedCameraRoll, HydratedPhoto, User } from '~~/types/types'
import { useUserStore } from '~~/store/user'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { getBlob, getStorage, ref as storageRef } from '@firebase/storage'
import { encode } from 'base64-arraybuffer'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Media } from '@capacitor-community/media'
import { Capacitor } from '@capacitor/core'
import { logEvent } from '~~/composables/useAnalytics'
import { useRouter } from 'vue-router'
import {useLoading} from "vue-loading-overlay";
import {useToast} from "vue-toastification";
import {loadDownloadedPhotosFromStorage, saveDownloadedPhotosToStorage} from "~/composables/useStorage";

const props = defineProps({
  photoid: {
    type: String,
  },
  rollid: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['close'])

const i18n = useI18n()
const locale = computed(() => i18n.locale.value)
const loading = useLoading({ color: '#fff', backgroundColor: '#000' })
const toast = useToast()

const store = useCameraRollsStore()

store.setup()

const userStore = useUserStore()
const router = useRouter()
const { user } = storeToRefs(userStore)

const currentSlide = ref<number>(0)
const carousel = ref(null)
const showDeleteDialog = ref(false)
const touchDrag = ref(true)

onMounted(async() => {
  focusAndBlurCarousel()
  window.addEventListener("resize", updateOrientation);

  // Init currentSlide index
  const initialIndex = photoIds.value.findIndex(id => id === props.photoid)
  currentSlide.value = initialIndex !== -1 ? initialIndex : 0

  // Load downloadedPhotos from storage
  downloadedPhotos.value = await loadDownloadedPhotosFromStorage();
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateOrientation);
});

const uiVisible = ref(true)

const toggleUI = () => {
  uiVisible.value = !uiVisible.value;
}

const isLandscape = ref(window.innerWidth > window.innerHeight);

const updateOrientation = () => {
  isLandscape.value = window.innerWidth > window.innerHeight;
}

const isLandscapeMode = computed(() => {
  return isLandscape.value;
});

const focusAndBlurCarousel = function () {
  // Focus Carousel for scroll left and right via keyboard
  const carouselElem = document.querySelector('.carousel') as HTMLInputElement
  carouselElem.focus()
  carouselElem.addEventListener('blur', () => carouselElem.focus())
}

const roll = computed((): HydratedCameraRoll => {
  return store.cameraRollsData.filter((c) => c.id == props.rollid)[0]
})

const photoIds = computed(() => {
  const rawPhotoIds = Object.keys(roll.value.photos)
  if (rawPhotoIds.length === 0) {
    router.push('/camera-rolls')
  }
  return rawPhotoIds
})

const currentPhoto = computed((): HydratedPhoto | undefined => {
  if (roll.value && currentSlide.value >= 0) {
    return roll.value.photos[photoIds.value[currentSlide.value]]
  }
})

const currentCreatedBy = computed((): User | undefined => {
  if (currentPhoto.value) {
    return currentPhoto.value?.createdBy
  }
})

const currentPhotoCaption = computed((): string => {
  if (roll.value && currentSlide.value >= 0) {
    return roll.value.photos[photoIds.value[currentSlide.value]].caption
  } else {
    return ''
  }
})

const next = function (event) {
  // @todo Avoid the "any"
  const carouselElem = carousel.value as any
  carouselElem.next()
}

const prev = function (event) {
  const carouselElem = carousel.value as any
  carouselElem.prev()
}

const userCanDelete = computed(() => {
  return currentPhoto.value?.createdBy?.uid === user.value?.uid || roll.value.userIsOwner
});

const clickDelete = function () {
  showDeleteDialog.value = true
}

const confirmDelete = function () {
  store.deletePhoto(props.rollid as string, photoIds.value[currentSlide.value])
  showDeleteDialog.value = false
  const carouselElem = carousel.value as any
  carouselElem.restartCarousel()
}

// stores the photos which have been downloaded by the user
const downloadedPhotos = ref<string[]>([]);


const clickDownload = async function () {
  let loader = loading.show()
  try {
    const storage = getStorage()
    const config = useRuntimeConfig()
    const filename = currentPhoto.value?.fileName
    if (filename === undefined) {
      return
    }
    const photoRef = storageRef(storage, `gs://${config.public.storageBucket}/photos/${filename}`)
    const blob = await getBlob(photoRef)

    if (!Capacitor.isNativePlatform()) {
      // source: https://stackoverflow.com/a/30832210/2454815
      let j = document.createElement('a')
      j.download = currentPhoto.value?.fileName as string
      j.href = URL.createObjectURL(blob)
      j.click()
    } else {
      if (downloadedPhotos.value.includes(currentPhoto.value?.fileName ?? '')) {
        // do not download the same photo twice
        return true
      }
      const arrayBuffer = await blob.arrayBuffer()

      const savedFile = await Filesystem.writeFile({
        path: filename,
        data: encode(arrayBuffer),
        directory: Directory.Data,
      })

      // Find the album called "PictureClub". create it if it does not exist.
      Media.savePhoto({
        path: savedFile.uri,
        // @ts-ignore due to outdated types in capacitor-community/media
        // see https://github.com/capacitor-community/media/pull/20#issuecomment-984362181
        album: { name: 'PictureClub' },
      })
    }
    const userTypeName = roll.value.userIsOwner ? 'Host' : 'Guest'
    logEvent(userTypeName + ' App usage', userTypeName + ' downloaded picture', filename)

    downloadedPhotos.value.push(filename)
    await saveDownloadedPhotosToStorage(downloadedPhotos.value);
  } catch (e) {
    toast.error(i18n.t('common.error'))
  } finally {
    loader.hide()
  }
}

const currentPhotoIsDownloaded = computed(() => {
  return downloadedPhotos.value.includes(currentPhoto.value?.fileName ?? '')
})

const dragEnable = function (v: boolean) {
  touchDrag.value = v
}
</script>
<template>
  <div>
    <!-- Slideshow component-->
    <div class="flex flex-col h-[calc(100dvh)]" @click="toggleUI">
      <div
        class="grow flex flex-col w-screen h-screen max-w-4xl 
        justify-center text-white z-30 bg-black touch-none 
        overflow-hidden"
      >
        <CameraRollPinchZoom
          img-selector=".carousel__slide--active img"
          @zoom-start="dragEnable(false)"
          @zoom-end="dragEnable(true)"
        >
          <Carousel
            v-if="roll"
            ref="carousel"
            class="carousel"
            :items-to-show="1"
            v-model="currentSlide"
            :wrap-around="true"
            :touch-drag="touchDrag"
            @keyup.right="next"
            @keyup.left="prev"
          >
            <slide v-for="photoId in photoIds" :key="photoId">
              <Photo
                :photo="roll.photos[photoId as string]"
                class="max-w-screen max-h-screen object-contain"
                loading="lazy"
                prefix="small_"
                :zooming="!touchDrag"
                :square="false"
              />
            </slide>
          </Carousel>
        </CameraRollPinchZoom>
        <div v-if="uiVisible" class="absolute max-w-4xl z-10 flex justify-between w-full p-4 pointer-events-none">
          <CommonIconButton :dark="true" @click.stop="prev" data-qa="prev" class="pointer-events-auto">
            <Icon icon="mdi:chevron-left" />
          </CommonIconButton>
          <CommonIconButton :dark="true" @click.stop="next" data-qa="next" class="pointer-events-auto">
            <Icon icon="mdi:chevron-right" />
          </CommonIconButton>
        </div>
      </div>
    </div>
    <div class="top-0 absolute h-screen w-screen max-w-4xl m-auto pointer-events-none">
      <!-- top bar -->
      <div
        v-if="currentPhoto && uiVisible"
        class="header__photo"
      >
        <div class="flex justify-between p-4">
          <CommonIconButton :dark="true" data-qa="slideshow-close" @click="emit('close')">
            <Icon icon="mdi:arrow-left" />
          </CommonIconButton>
          <div v-if="currentPhoto.createdBy" class="header__photo-text">
            <div v-if="currentCreatedBy" class="flex items-center justify-center">
              <img
                v-if="currentCreatedBy.photoURL"
                :src="currentCreatedBy.photoURL"
                class="rounded-full h-4 w-4 mr-1"
              />
              <div>{{ currentCreatedBy.displayName }}</div>
            </div>
            <div v-if="currentPhoto.createdAt" class="text-stone-400 text-xs">
              {{ useDistanceText(currentPhoto.createdAt, locale) }}
            </div>
          </div>

          <CommonIconButton
            :dark="true"
            class="pointer-events-auto"
            :class="{ invisible: !userCanDelete }"
            @click="clickDelete"
            data-qa="delete"
          >
            <Icon icon="mdi:trashcan" />
          </CommonIconButton>
        </div>
        <div class="h-14 px-4 transition-all" :class="{ 'h-0': !currentPhotoCaption }">
          <div
            v-if="currentPhotoCaption && !isLandscapeMode"
            class="rounded-xl p-2 my-4 border-0 text-stone-100 bg-stone-800 truncate pointer-events-auto"
            data-qa="caption"
          >
            {{ currentPhotoCaption }}
          </div>
        </div>
      </div>
      <!-- bottom bar -->
      <div
        v-if="currentPhoto && uiVisible"
        class="footer__photo"
      >
        <div class="flex justify-end gap-3 items-center p-4 touch-none">
          <div
            v-if="currentPhotoCaption && isLandscapeMode"
            class="rounded-xl p-2 my-4 border-0 text-stone-100 bg-stone-800 truncate pointer-events-auto"
            data-qa="caption"
          >
            {{ currentPhotoCaption }}
          </div>
          <template v-if="Capacitor.isNativePlatform()">
            <CommonIconButton :dark="true" class="pointer-events-auto" @click="clickDownload" data-qa="btn-download">
              <Icon v-if="currentPhotoIsDownloaded" icon="mdi:check" />
              <Icon v-else icon="mdi:download" />
            </CommonIconButton>
          </template>
          <template v-else>
            <CommonIconButton :dark="true" class="pointer-events-auto" @click="clickDownload" data-qa="btn-download">
              <Icon v-if="currentPhotoIsDownloaded" icon="mdi:check" />
              <Icon v-else icon="mdi:download" />
            </CommonIconButton>
          </template>
        </div>
      </div>
    </div>
    <CommonDialog
      :show="showDeleteDialog"
      :positive-label="$t('slideshow.delete.positiveLabel')"
      :negative-label="$t('slideshow.delete.negativeLabel')"
      :body="$t('slideshow.delete.body')"
      @click-negative="showDeleteDialog = false"
      @click-positive="confirmDelete"
    />
  </div>
</template>
<style>
/* See https://github.com/ismail9k/vue3-carousel/issues/22 */
.carousel__slide--visible {
  transform: rotateY(0);
  @apply z-30;
}

.carousel__viewport {
  overflow: visible;
}

.carousel:focus-visible {
  outline: none;
}

.header__photo {
  @apply w-full max-w-4xl fixed top-0 z-40 pt-safe touch-none pointer-events-auto bg-stone-900;
}

.footer__photo {
  @apply w-full max-w-4xl fixed bottom-0 z-40 bg-stone-900 flex flex-col justify-between pb-safe pointer-events-auto;
}

@media only screen and (orientation: landscape) {
  .header__photo,
  .footer__photo {
    @apply bg-transparent;
  }
}

.header__photo-text {
  @apply flex flex-col items-center justify-center text-white text-sm;
}

@media only screen and (orientation: landscape) {
  .header__photo-text {
    @apply p-2 rounded-lg bg-stone-900;
  }
}

</style>
