<script setup lang="ts">

import { storeToRefs } from 'pinia';
import { PropType, ref, onMounted } from 'vue';
import { useCameraRollsStore } from '~~/store/camera-rolls';
import { useUserStore } from '~~/store/user';
import { HydratedCameraRoll } from '~~/types/types';

const props = defineProps({
    cameraRoll: {
        type: Object as PropType<HydratedCameraRoll>,
        required: true
    },
})

const rolls = useCameraRollsStore()
const userStore = useUserStore()
const { user, userState } = storeToRefs(userStore)
const errorTitleEmpty = ref(false);
const membersCanInvite = ref(false);
const openCameraRoll = ref(true);
const cameraRollName = ref('');
const rollNameElem = ref<HTMLInputElement>();


const updateName = (event: Event) => {
  const target = event.target as HTMLInputElement;
  errorTitleEmpty.value = !target.value.trim();
  if (!errorTitleEmpty.value) {
    rolls.updateCameraRoll(props.cameraRoll.id, target.value, membersCanInvite.value, openCameraRoll.value);
  }
};


const updateMembers = (value: boolean) => rolls.updateCameraRoll(props.cameraRoll.id, cameraRollName.value, value, openCameraRoll.value);
const updateOpenedCameraRoll = (value: boolean) => rolls.updateCameraRoll(props.cameraRoll.id, cameraRollName.value, membersCanInvite.value, value);


onMounted(() => {
    if (props.cameraRoll) {
        membersCanInvite.value = props.cameraRoll.membersCanInvite
        cameraRollName.value = props.cameraRoll.title
        openCameraRoll.value = !props.cameraRoll.closed
    }
})

</script>
<template>
    <div class="flex flex-col items-start">
        <input
            type="text"
            ref="rollNameElem"
            v-model="cameraRollName"
            :placeholder="$t('new-cameraroll.placeholder')"
            class="w-full mt-6 text-2xl font-medium outline-0"
            @input="updateName"
            data-qa="input-name">
      <div class="text-xs text-left text-stone-500 mt-2">{{ $t('new-cameraroll.edit-description') }}</div>
      <div v-if="errorTitleEmpty" class="text-sm text-red-500">{{ $t('new-cameraroll.error-empty-title') }}</div>
      <div class="mt-6 w-full">
        <LabelledSwitch
            name="members-can-invite"
            position="top"
            v-model="membersCanInvite"
            :label="$t('new-cameraroll.member-can-invite')"
            @update:model-value="updateMembers" />
        <LabelledSwitch
            name="close-camera-roll"
            position="bottom"
            v-model="openCameraRoll"
            :label="$t('new-cameraroll.open')"
            :description="$t('new-cameraroll.open-description')"
            @update:model-value="updateOpenedCameraRoll" />
      </div>
    </div>
</template>
