<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import { useToast } from 'vue-toastification'
import { useI18n } from 'vue-i18n'
import useInviteURL from '~~/composables/useInviteURL'
import { urlEncode } from '@sentry/utils'

const props = defineProps({
  rollId: {
    type: String,
    required: true,
  },
})

const i18n = useI18n()
const toast = useToast()

const isNativePlatform = computed(() => {
  return Capacitor.isNativePlatform()
})

const config = useRuntimeConfig()

const inviteUrl = useInviteURL(props.rollId)
const qrCodeImageUrl = `${config.public.qrGeneratorLink}?size=226x226&text=${encodeURIComponent(inviteUrl)}`

async function share() {
  await Share.share({
    title: i18n.t('invite-qr-code.share.title'),
    text: i18n.t('invite-qr-code.share.text'),
    url: inviteUrl,
    dialogTitle: i18n.t('invite-qr-code.share.dialog-title'),
  })
  logEvent('Host App usage', 'Share Link', props.rollId)
}

const copyToClipboard = () => {
  navigator.clipboard.writeText(inviteUrl)
  toast.success(i18n.t('invite-qr-code.copied-to-clipboard'))
}

const print = () => {
  const config = useRuntimeConfig()
  logEvent('Host App usage', 'Print QR Code', props.rollId)
  window.open(`${config.public.functionsUrl}/printableQrCodePdf?rollid=${props.rollId}`)
}
</script>
<template>
  <div>
    <div class="flex flex-col justify-center items-center text-center bg-stone-100 rounded-xl p-4 my-4">
      <div class="mb-4 font-medium text-stone-500">{{ $t('invite-qr-code.header') }}</div>
      <div class="text-sm w-2/3 mb-4 text-stone-500">{{ $t('invite-qr-code.info') }}</div>
      <div class=""><img :src="qrCodeImageUrl" /></div>
      <div class="mt-4">
        <CommonButton :dark="false" @click="print">
          <div class="flex items-center">
            <Icon icon="mdi:printer" height="24" class="mr-2" />
            {{ $t('invite-qr-code.print') }}
          </div>
        </CommonButton>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center text-center bg-stone-100 rounded-xl p-4 my-4">
      <div class="mb-4 font-medium text-stone-500">{{ $t('invite-qr-code.header-share') }}</div>
      <template v-if="isNativePlatform">
        <CommonButton :dark="false" class="w-full" @click="share"
          ><Icon icon="mdi:share" class="mr-2" height="24" /> {{ $t('invite-qr-code.button-share') }}</CommonButton
        >
      </template>
      <template v-else>
        <div class="text-sm w-2/3 mb-4 text-stone-500">
          {{ inviteUrl }}
        </div>
        <CommonButton :dark="false" class="w-full" @click="copyToClipboard"
          ><Icon icon="material-symbols:content-copy" class="mr-2" height="24" />
          {{ $t('invite-qr-code.button-copy') }}</CommonButton
        >
      </template>
    </div>
  </div>
</template>
