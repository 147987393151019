<script setup lang="ts">
// Shows member list for a camera roll
import { computed, PropType } from 'vue'
import {HydratedCameraRoll, User} from '~~/types/types'
import { CameraRollDialogState } from '~~/types/enums'
import { useUserStore } from '~~/store/user'
import { storeToRefs } from 'pinia'
import { UserStates } from '~~/types/enums'

const emits = defineEmits(['close', 'click-invite', 'click-status', 'click-more'])

const props = defineProps({
  cameraRoll: {
    type: Object as PropType<HydratedCameraRoll>,
    required: true,
  },
  dialogState: {
    type: Number as PropType<CameraRollDialogState>,
    default: 0,
  },
})

const userStore = useUserStore()
const { user, userState } = storeToRefs(userStore)

const members = computed(() => {
  /*
  * Sorting: Members should be sorted by:
  * Presence of a displayName, in alphabetical order.
  * If displayName is absent, by the presence of an email.
  * If neither displayName nor email is present, these members should come last.
  */

  const filteredMembers = props.cameraRoll.members.filter(m => m?.uid);

  return filteredMembers.sort((a, b) => {
    const aName = a.displayName || '';
    const bName = b.displayName || '';
    const aEmail = a.email || '';
    const bEmail = b.email || '';

    if (aName && bName) {
      return aName.localeCompare(bName);
    } else if (aName) {
      return -1;
    } else if (bName) {
      return 1;
    }

    if (aEmail && bEmail) {
      return aEmail.localeCompare(bEmail);
    } else if (aEmail) {
      return -1;
    } else if (bEmail) {
      return 1;
    }

    return 0;
  });
});

const username = (member: User) => {
  return member.displayName ? member.displayName : member.email
}

const userCanInvite = computed(() => {
  return props.cameraRoll?.userIsOwner || props.cameraRoll?.membersCanInvite
})

</script>
<template>
  <div>
    <CommonBottomModal :show="props.dialogState == CameraRollDialogState.SelectTask" @close="$emit('close')">
      <div class="flex w-full justify-start items-center mb-1">
        <CommonIconButton @click="$emit('close')" data-qa="close">
          <Icon icon="mdi:close" />
        </CommonIconButton>
        <div class="flex left-1/2 absolute transform -translate-x-1/2 text-center font-medium">{{ $t('camera-rolls.properties') }}</div>
      </div>

      <!-- Edit -->
      <CameraRollEdit
          v-if="props.cameraRoll?.userIsOwner"
          ref="cameraRollEdit"
          :camera-roll="cameraRoll"
          :is-owner="props.cameraRoll?.userIsOwner"
          @close="$emit('close')"
      />

      <!-- MemberList & Invite -->
      <div
        class="flex justify-between items-center container rounded-t-2xl bg-stone-100 mt-4 p-3 pb-2 border-b"
      >
        <div class="text-sm text-left text-stone-600 font-medium uppercase"> {{ members.length }} {{ $t('camera-rolls.member', members.length) }} </div>
        <CommonButton v-if="userCanInvite" :dark="false" @click="$emit('click-invite')">
          <div class="flex items-center">
            <Icon icon="material-symbols:qr-code" height="24" class="mr-2" />
            {{ $t('invite') }}
          </div>
        </CommonButton>
      </div>
      <ul class="container bg-stone-100 p-3 pt-1 text-sm text-left max-h-64	overflow-scroll">
        <li v-for="member in members" :key="member.uid">
          <div class="flex w-full items-center mt-2" :data-qa="member.uid">
            <CommonAvatar :user="member" :dark="true" class="mr-3" />
            <div class="font-medium text-stone-900">
              {{ username(member) }}
              <Icon
                v-if="member && member.uid == cameraRoll.createdBy?.uid"
                icon="mdi:crown"
                class="inline"
                height="18"
              >
              </Icon>
            </div>
          </div>
        </li>
      </ul>

      <!-- Delete -->
      <CameraRollDelete :camera-roll="cameraRoll" />
    </CommonBottomModal>
    <CommonBottomModal :show="props.dialogState == CameraRollDialogState.InviteQRCode" @close="$emit('close')">
      <CameraRollInvite @close="$emit('close')" :roll-id="cameraRoll.id"></CameraRollInvite>
    </CommonBottomModal>
  </div>
</template>

# "message": "Sending SignInWithEmailLink link
http://127.0.0.1:9099/emulator/action?mode=signIn&lang=en&oobCode=9QUz2y66Ocw4X5vSi4022YlC3wLXZSRTcWnNWr1WaQm0sOa8V9XWZb&apiKey=fake-api-key&continueUrl=http%3A%2F%2Flocalhost:3000%2Fregister%3Ftype%3Dpasswordless%26targetpath%3D%252Fcamera-rolls
