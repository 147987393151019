<script lang="ts" setup>
const props = defineProps({
  /*
  The query selector for the image element. Is there a better way to do this?
  */
  imgSelector: {
    type: String,
    default: 'img',
  },
})

const emits = defineEmits(['zoomStart', 'zoomEnd'])

const evCache = ref<any[]>([])
const centerX = ref<number | null>(null)
const centerY = ref<number | null>(null)
const imgTop = ref(0)
const imgLeft = ref(0)
const imgWidth = ref(0)
const imgHeight = ref(0)
const initialDistance = ref<number | null>(null)
const prevDiff = ref(-1)
const root = ref<HTMLElement | null>(null)
let eventHandler = {} as { [key: string]: Function }

const MAX_ZOOM_RATIO = 3

onMounted(() => {
  const pointerdownHandler = (ev: any) => {
    evCache.value.push(ev)
  }

  const pointermoveHandler = (ev: any) => {
    setImageDimensions()
    for (let i = 0; i < evCache.value.length; i++) {
      if (ev.pointerId == evCache.value[i].pointerId) {
        evCache.value[i] = ev
        break
      }
    }

    // If two pointers are down, check for pinch gestures
    if (evCache.value.length == 2) {
      emits('zoomStart')
      if (centerX.value === null) {
        centerX.value = Math.abs(evCache.value[1].clientX + evCache.value[0].clientX) / 2 - imgLeft.value
      }

      if (centerY.value === null) {
        centerY.value = Math.abs(evCache.value[1].clientY + evCache.value[0].clientY) / 2 - imgTop.value
      }

      console.log('zoom center', centerX.value, centerY.value)

      // Calculate the distance between the two pointers
      const curDiff = Math.sqrt(
        Math.pow(evCache.value[1].clientX - evCache.value[0].clientX, 2) +
          Math.pow(evCache.value[1].clientY - evCache.value[0].clientY, 2)
      )

      if (initialDistance.value === null) {
        initialDistance.value = curDiff
      }

      // Calculate the center point of the zoom

      if (prevDiff.value > 0) {
        let ratio = curDiff / initialDistance.value

        // This ensures that the zoom center is
        const translateX = (ratio * (imgWidth.value / 2 - centerX.value) - imgWidth.value / 2 + centerX.value) / ratio
        const translateY = (ratio * (imgHeight.value / 2 - centerY.value) - imgHeight.value / 2 + centerY.value) / ratio

        zoom(ratio, translateX, translateY)
      }

      // Cache the distance for the next move event
      prevDiff.value = curDiff
    }
  }

  const zoom = (factor: number, translateX: number, translateY: number) => {
    const el = getImageElement()
    const ratio = factor >= MAX_ZOOM_RATIO ? MAX_ZOOM_RATIO : factor
    if (el) {
      el.style.transform = `scale(${ratio}) translateX(${translateX}px) translateY(${translateY}px)`
    }
  }

  const pointerupHandler = (ev: any) => {
    // Remove this pointer from the cache and reset the target's
    // background and border
    removeEvent(ev)

    // If the number of pointers down is less than two then reset diff tracker
    if (evCache.value.length < 2) {
      emits('zoomEnd')
      prevDiff.value = -1
      zoom(1, 0, 0)
      initialDistance.value = null
      centerX.value = null
      centerY.value = null
      resetImageDimensions()
    }
  }

  const removeEvent = (ev: any) => {
    // Remove this event from the target's cache
    for (var i = 0; i < evCache.value.length; i++) {
      if (evCache.value[i].pointerId == ev.pointerId) {
        evCache.value.splice(i, 1)
        break
      }
    }
  }

  const getImageElement = (): HTMLElement | null | undefined => {
    return root.value?.querySelector(props.imgSelector)
  }

  const setImageDimensions = () => {
    // store original position of image
    const img = getImageElement()
    if (img instanceof HTMLImageElement && !imgWidth.value) {
      const viewportOffset = img.getBoundingClientRect()
      // these are relative to the viewport, i.e. the window
      imgTop.value = viewportOffset.top
      imgLeft.value = viewportOffset.left
      imgWidth.value = img.offsetWidth
      imgHeight.value = img.offsetHeight
    }
  }

  const resetImageDimensions = () => {
    imgTop.value = 0
    imgLeft.value = 0
    imgWidth.value = 0
    imgHeight.value = 0
  }

  const init = () => {
    // We attach it on the whole window, otherwise pointerup is triggered
    // when the mouse leaves the original image position
    const el = window

    eventHandler = {
      pointerdown: pointerdownHandler,
      pointermove: pointermoveHandler,
      pointerup: pointerupHandler,
      pointercancel: pointerupHandler,
      pointerout: pointerupHandler,
      pointerleave: pointerupHandler,
    }

    for (const name of Object.keys(eventHandler)) {
      window.addEventListener(name, eventHandler[name] as EventListener)
    }

    setImageDimensions()
  }

  init()
})

onUnmounted(() => {
  emits('zoomEnd')
  for (const name of Object.keys(eventHandler)) {
    window.removeEventListener(name, eventHandler[name] as EventListener)
  }
})
</script>
<template>
  <div ref="root">
    <slot></slot>
  </div>
</template>
