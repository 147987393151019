<script setup lang="ts">
import {useCameraRollsStore} from '~~/store/camera-rolls'
import {CameraRollDialogState as State} from '~~/types/enums'
import {useGroupByHour} from '~~/composables/usePhotoDisplayTools'
import {useUserStore} from '~~/store/user'
import {storeToRefs} from 'pinia'
import {useI18n} from 'vue-i18n'
import {logEvent} from '~~/composables/useAnalytics'
import {computed, nextTick, ref, onMounted, watch} from "vue"
import Loader from "~/components/Common/Loader.vue"
import { useRoute } from 'vue-router'

const i18n = useI18n()
const locale = computed(() => i18n.locale.value)
const pageRef = ref(null)
const store = useCameraRollsStore()
store.setup()

const route = useRoute()

const showProperties = ref<State>(State.Closed)
const photoListElem = ref<HTMLDivElement>()
const scrollablePart = ref<HTMLDivElement>()
const headerElem = ref<HTMLDivElement>()
const showSlideshow = ref(false)
const slideshowVisible = ref(false)
const slideshowPhotoId = ref()
const loading = ref(false)

const userStore = useUserStore()
const {user, userState} = storeToRefs(userStore)

const roll = computed(() => {
  return store.cameraRollsData.filter((c) => c.id == route.params.id)[0]
})

const photoCount = computed(() => Object.keys(roll.value.photos).length)

const photosGroupedPerHour = computed(() => {
  const photoDisplayGroups = useGroupByHour(roll.value.photos, 6, new Date())
  return photoDisplayGroups.map((g) => useGenerateGalleryMasonry(g, Math.random() > 0.5))
})

const openCamerarollProperties = function () {
  showProperties.value = State.SelectTask
}

/**
 * @todo: Get rid of this unnecessary logic
 * Scroll the element "scrollablePart" to right below the sticky header
 */
function scrolltostart() {
  if (photoListElem.value && headerElem.value && scrollablePart.value) {
    photoListElem.value.scrollIntoView()
    const headerDimensions = headerElem.value.getBoundingClientRect()
    scrollablePart.value.scrollBy(0, -headerDimensions.height)
  }
}

function doCloseSlidehow() {
  showSlideshow.value = false
  document.body.classList.remove('noscroll')
  slideshowVisible.value = false
}

const animationDuration = 600
const slideshowRevealDuration = 200
const slideshowRevealStart = animationDuration - slideshowRevealDuration

async function doShowSlideshow(ev: MouseEvent, photoid: String) {
  // Logic for eye-candy animation which smoothlies moves the image from the thumbnail to the slideshow

  ev.preventDefault()
  if (!ev.currentTarget) return
  const currentTarget = ev.currentTarget as HTMLElement
  const sourceImg = currentTarget.firstChild?.firstChild as HTMLImageElement
  const sourceImgDimensions = sourceImg.getBoundingClientRect()
  const clone = sourceImg?.cloneNode(true) as HTMLElement
  clone.style.position = 'absolute'
  clone.style.top = sourceImgDimensions.top + 'px'
  clone.style.left = sourceImgDimensions.left + 'px'
  clone.style.width = sourceImgDimensions.width + 'px'
  clone.style.height = sourceImgDimensions.height + 'px'
  document.body.appendChild(clone)

  // show hidden gallery, measure active slide
  slideshowPhotoId.value = photoid
  showSlideshow.value = true
  slideshowVisible.value = false
  await nextTick()
  const slideShowWrapperRect = pageRef?.value?.getBoundingClientRect()
  const targetImageWidth = slideShowWrapperRect?.width ? slideShowWrapperRect.width + 'px' : '100vw'
  const targetImageHeight = Math.floor((sourceImg.naturalHeight / sourceImg.naturalWidth) * (slideShowWrapperRect?.width ? slideShowWrapperRect.width : window.innerWidth))
  const targetImageLeftPosition = slideShowWrapperRect?.left ? slideShowWrapperRect.left + 'px' : '0px'
  const imageAnimation = [
    {
      top: sourceImgDimensions.top + 'px',
      left: sourceImgDimensions.left + 'px',
      width: sourceImgDimensions.width + 'px',
      height: sourceImgDimensions.height + 'px',
    },
    {
      top: Math.floor((window.innerHeight - targetImageHeight) / 2) + 'px',
      left: targetImageLeftPosition,
      width: targetImageWidth,
      height: targetImageHeight + 'px',
    },
  ]
  const imageAnimationTiming = {
    duration: animationDuration,
    iterations: 1,
    easing: 'ease-in-out',
    fill: 'forwards',
  } as KeyframeAnimationOptions
  clone.animate(imageAnimation, imageAnimationTiming)

  window.setTimeout(() => {
    slideshowVisible.value = true
  }, slideshowRevealStart)

  window.setTimeout(() => {
    clone.remove()
  }, animationDuration + 200) // setTimeout is not very precise in scheduling animations, add some time to fix blinking
}

function clickInvite() {
  showProperties.value = State.InviteQRCode
  logEvent('Host App usage', 'Click Show QR Code', roll.value.id)
}

const toggleLoading = (value: boolean) => {
  loading.value = value
}

const userCanUpload = computed(() => {
  return roll.value?.userIsOwner || !roll.value?.closed
})

watch (slideshowVisible, (isVisible) => {
  if (isVisible) {
    document.body.style.backgroundColor = 'black'
  } else {
    document.body.style.backgroundColor = 'inherit'
  }
})

onMounted(async () => {
  if (route.query.scrolltostart) {
    window.setTimeout(scrolltostart, 100)
  }
})
</script>
<template>
  <div>
    <!-- Prevent that the area with the slideshow extends over the bottom edge of the mobile -->
    <div ref="pageRef" class="flex flex-col" style="max-height: calc(100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom))" v-if="roll">
      <!-- The slideshow floats over the background for the nice animation between photo and slideshow -->
      <Slideshow
          v-if="showSlideshow"
          class="absolute top-0 transition-opacity z-20 opacity-0"
          :class="{
          [`duration-${slideshowRevealDuration}`]: true,
          'opacity-100': slideshowVisible }"
          :rollid="roll.id"
          :photoid="slideshowPhotoId"
          @close="doCloseSlidehow()"
      >
      </Slideshow>
      <CameraRollProperties
          :camera-roll="roll"
          :dialog-state="showProperties"
          @close="showProperties = State.Closed"
          @click-invite="clickInvite"
      />
      <Navigation :fixed="false" :safe="false">
        <template #left>
          <NuxtLink to="/camera-rolls" data-qa="link-back">
            <CommonIconButton :dark="false" :disabled="false">
              <Icon icon="mdi:arrow-left"/>
            </CommonIconButton>
          </NuxtLink>
        </template>
        <template #default>
          <h1 class="font-medium text-lg">{{ roll.title }}</h1>
          <div class="text-sm capitalize">
            {{ photoCount }}
            <Icon icon="mdi:image" class="inline-block mb-0.5 mr-1"/>
            {{ roll.members.length }}
            <Icon icon="material-symbols:person" class="inline-block mb-0.5"/>
          </div>
        </template>
        <template #right class="overscroll-contain">
          <CommonIconButton :dark="false" :disabled="false" @click="openCamerarollProperties" data-qa="properties">
            <Icon height="24" icon="mdi:dots-horizontal"/>
          </CommonIconButton>
        </template>
      </Navigation>
      <section ref="scrollablePart" class="overflow-scroll photogrid">
        <div v-if="loading" class="flex items-center justify-center pb-2">
          <Loader :size="24" />
        </div>
        <div ref="photoListElem">
          <div v-for="(displayGroup, key) in photosGroupedPerHour" :key="key">
            <div class="bg-stone-100 text-stone-500 text-sm p-2 text-center font-medium uppercase">
              {{ useDistanceText(displayGroup.refDate, locale) }}
            </div>
            <div v-for="(row, ix) in displayGroup.masonryRows" key="row" data-qa="cameraroll-masonryrow">
              <!-- Photo Masonry -->
              <div v-if="row.type == 'large_left'">
                <div class="flex flex-row">
                  <div v-if="row.photoIds[0]" @click="doShowSlideshow($event, row.photoIds[0])" class="w-2/3">
                    <Photo :photo="roll.photos[row.photoIds[0]]" prefix="small_"/>
                  </div>
                  <div class="flex flex-col w-1/3">
                    <div v-if="row.photoIds[1]" @click="doShowSlideshow($event, row.photoIds[1])" class="w-full">
                      <Photo :photo="roll.photos[row.photoIds[1]]" prefix="small_"/>
                    </div>
                    <div v-if="row.photoIds[2]" @click="doShowSlideshow($event, row.photoIds[2])" class="w-full">
                      <Photo :photo="roll.photos[row.photoIds[2]]" prefix="small_"/>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="row.type == 'large_right'">
                <div class="flex flex-row">
                  <div class="flex flex-col w-1/3">
                    <div v-if="row.photoIds[0]" @click="doShowSlideshow($event, row.photoIds[0])" class="w-full">
                      <Photo :photo="roll.photos[row.photoIds[0]]" prefix="small_"/>
                    </div>
                    <div v-if="row.photoIds[1]" @click="doShowSlideshow($event, row.photoIds[1])" class="w-full">
                      <Photo :photo="roll.photos[row.photoIds[1]]" prefix="small_"/>
                    </div>
                  </div>
                  <div v-if="row.photoIds[2]" @click="doShowSlideshow($event, row.photoIds[2])" class="w-2/3">
                    <Photo :photo="roll.photos[row.photoIds[2]]" prefix="small_"/>
                  </div>
                </div>
              </div>
              <div v-if="row.type == 'normal'" class="flex flex-row">
                <div v-for="photoId in row.photoIds" class="w-1/3" @click="doShowSlideshow($event, photoId)">
                  <Photo v-if="photoId" :photo="roll.photos[photoId]" :id="ix" prefix="small_"/>
                </div>
              </div>
            </div>
          </div>
          <div v-if="photosGroupedPerHour.length == 0" class="m-5">
            <CameraRollInviteQRCode :roll-id="(route.params.id as string)"/>
          </div>
        </div>
      </section>
    </div>
    <PhotoUpload v-if="!roll || userCanUpload" @toggle-loading="toggleLoading"></PhotoUpload>
  </div>
</template>
<style>
.photogrid img {
  @apply p-0.5;
}
</style>
